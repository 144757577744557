import classNames from "classnames";
import * as classes from "./style.module.scss";

interface ExpandDimensionProps {
  children: React.ReactNode;
  className?: string;
  expandDirection?: "x" | "y"; // New prop to control expansion direction
}

const ExpandDimension: React.FC<ExpandDimensionProps> = ({
  children,
  className,
  expandDirection,
}) => {
  return (
    <div
      className={classNames(className, classes.wrapper, {
        [classes.x]: expandDirection === "x",
        [classes.y]: expandDirection === "y",
      })}
    >
      <div className={classes.inner}>{children}</div>
    </div>
  );
};

export default ExpandDimension;
