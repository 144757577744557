// https://github.com/jelaxshan/useFetch
import { useEffect, useState } from "react";

export const useFetch = <T>({
  url,
  options,
  enabledCache = false,
}: {
  url: string | null;
  enabledCache?: boolean;
  options?: RequestInit | undefined;
}): {
  data: T | null;
  error: Error | null;
  isLoading: boolean;
} => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    let cancelRequest = false;
    if (!url) {
      return;
    }

    const fetchData = async (): Promise<void> => {
      const cachedData = sessionStorage.getItem(url);

      setIsLoading(true);

      if (cachedData && enabledCache) {
        try {
          const parsedData = JSON.parse(cachedData) as T;
          if (!cancelRequest) {
            setData(parsedData);
            setIsLoading(false);
          }
        } catch (parsingError) {
          if (!cancelRequest) {
            setError(new Error("Failed to parse cached data"));
            setIsLoading(false);
          }
        }
      } else {
        try {
          const res = await fetch(url, options);
          if (!res.ok) {
            throw new Error(`Fetch failed with status: ${res.status}`);
          }
          const response = await res.json();
          if (!cancelRequest) {
            setData(response as T); // Ensure type safety
            if (enabledCache) {
              sessionStorage.setItem(url, JSON.stringify(response));
            }
            setIsLoading(false);
          }
        } catch (err) {
          if (!cancelRequest) {
            setError(err as Error); // Type the error explicitly
            setIsLoading(false);
          }
        }
      }
    };

    fetchData();

    return () => {
      cancelRequest = true;
    };
  }, [url, options, enabledCache]);

  return { data, error, isLoading };
};
