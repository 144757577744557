import { splitKeyValue } from "~/src/util/splitKeyValue";

export interface Suggestion {
  id: string;
  title: string;
  content?: Suggestion[];
}

export interface BackendInfo {
  autocomplete: BackendAutocomplete[];
}

export interface BackendAutocomplete {
  value: string;
  source: string;
}

export const buildTree = (
  backendSuggestions: BackendAutocomplete[],
): Suggestion[] => {
  const suggestionsMap: { [key: string]: Suggestion } = {};

  backendSuggestions.forEach((backendSuggestion) => {
    const [key, title] = splitKeyValue(backendSuggestion.value);
    const [level, subLevel] = key.split(".");

    if (!suggestionsMap[level]) {
      // Initialize top-level suggestion if not already present
      suggestionsMap[level] = {
        id: `${level}`,
        title: subLevel === "0" ? title.trim() : "", // Set title only for top-level items
        content: [],
      };
    }

    if (subLevel === "0" || !subLevel) {
      // Update title for top-level suggestions
      suggestionsMap[level].title = title.trim();
    } else {
      // Add sub-level suggestion
      suggestionsMap[level].content?.push({
        id: `${level}.${subLevel}`,
        title: title.trim(),
      });
    }
  });

  // Convert the map back into an array of suggestions
  const result = Object.values(suggestionsMap);

  // Sort content at each level
  const sortContentRecursively = (content: Suggestion[]): void => {
    content.sort((a, b) =>
      a.id.localeCompare(b.id, undefined, { numeric: true }),
    );
    content.forEach((item) => {
      if (item.content && item.content.length > 0) {
        sortContentRecursively(item.content);
      }
    });
  };

  sortContentRecursively(result);
  return result;
};
