.icon {
  --icon-size: 20px; // Default icon size
  --line-length: calc(var(--icon-size) / 10 * 6);
  --stroke-width: calc(var(--icon-size) / 10); // 2px for default

  position: relative;
  display: inline-block;
  width: var(--icon-size);
  height: var(--icon-size);
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.line {
  top: 50%;
  left: 50%;
  position: absolute;
  background-color: currentColor;
  border-radius: var(--stroke-width);
  transition-property: opacity, transform;
}

.horizontal {
  width: var(--line-length);
  height: var(--stroke-width);
  transform: translate(-50%, -50%);
}

.vertical {
  width: var(--stroke-width);
  height: var(--line-length);
  transform: translate(-50%, -50%);
}

.open .vertical {
  transform: translate(-50%, -50%) rotate(90deg);
  opacity: 0;
}

.open .horizontal {
  transform: translate(-50%, -50%) rotate(180deg);
}
