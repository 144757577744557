import isMobileDevice from "ismobilejs";
import { Dispatch, SetStateAction } from "react";

export enum DEFAULT_SHORT_NAME {
  MODEL_3_5_4k = "3.5-4k",
  MODEL_3_5_16k = "3.5-16k",
  MODEL_4_TURBO = "4-turbo",
  MODEL_4_0_8k = "4-8k",
  MODEL_4o = "4o",
  MODEL_4o_8k = "4o-8k",
  MODEL_4o_16k = "4o-16k",
  MODEL_4o_mini = "4o-mini",
  MODEL_4o_mini_8k = "4o-mini-8k",
  MODEL_4o_mini_16k = "4o-mini-16k",
}

export interface UsedLinksDto {
  url: string;
  title?: string;
  type?: string;
  description?: string;
  date?: string;
  fromFullTextSearch?: boolean; // todo sh: is this correct?
  content?: string; // todo sh: is this correct?
  contentDescription?: boolean; // todo sh: is this correct?
  similarity?: number; // todo sh: is this correct?

  considered: boolean;
  summary?: string;
  image?: string;
  smallImage?: string;
  orgMetadata?: Record<string, any>;
}

export const replaceLinks = (input: string, usedLinks: UsedLinksDto[]) => {
  const linkMatches = input.match(/\[\^([\^0-9, ]+)]/g);

  if (linkMatches) {
    let newInput = input;

    linkMatches.forEach((fMatch) => {
      fMatch.split(",").forEach((match) => {
        const linkNumber = match.match(/([0-9]+)/g)?.[0];

        if (!linkNumber) {
          return;
        }
        const link = linkNumber && usedLinks[parseInt(linkNumber) - 1];
        if (link) {
          newInput = newInput.replace(
            match,
            ` ([Link ${linkNumber}](${link.url} "__link: ${linkNumber}"))`,
          );
        } else {
          // if we don't have a link, remove the link
          newInput = newInput.replace(match, ``);
        }
      });
    });
    return newInput;
  }
  return input;
};
