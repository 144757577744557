// this is legacy hacky stuff. as soon as we have enough browser support we can use

/*
.wrapper {
  display: none;

  transition: height 0.2s ease-in-out;
  transition-behavior: allow-discrete;

  height: 0; 
  width: 0;
  @starting-style {
    height: 0;
    width: 0;
  }

  &.expand {
    height: calc-size(auto);
    width: calc-size(auto);
  }
}
*/

.wrapper {
  display: grid;
  grid-template-rows: 0fr;
  grid-template-columns: 0fr;
  animation-duration: var(--root--transition-duration);
  animation-timing-function: var(--root--transition-timing-function);
  animation-fill-mode: forwards;
}

.forceExpand.wrapper,
.expandOnHover:hover .wrapper {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
}

.inner {
  overflow: hidden;
}

.x {
  grid-template-rows: 1fr;
}

.y {
  grid-template-columns: 1fr;
}
