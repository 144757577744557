import React from "react";
import ReactDOM from "react-dom/client";
import CityGPT from "~/src/components/index";

import { PUBLISH_URL, STARTUP_MESSAGE } from "~/src/config/env";

try {
  class CityGPTElement extends HTMLElement {
    private root: ShadowRoot;

    constructor() {
      super();
      this.root = this.attachShadow({ mode: "open" });
    }

    static get observedAttributes() {
      return ["org"];
    }

    connectedCallback() {
      this.render();
    }

    attributeChangedCallback() {
      this.render();
    }

    render() {
      const lang = this.getAttribute("lang") || "de";
      const context = this.getAttribute("context") || null;

      // Clear the shadow root
      while (this.root.firstChild) {
        this.root.removeChild(this.root.firstChild);
      }

      // Attach external stylesheet
      const linkElem = document.createElement("link");
      linkElem.setAttribute("rel", "stylesheet");
      linkElem.setAttribute("href", `${PUBLISH_URL}/city-gpt.css`);

      this.root.appendChild(linkElem);

      // Create a container div for React to render into
      const container = document.createElement("div");
      container.classList.add("city-gpt");
      this.root.appendChild(container);

      ReactDOM.createRoot(container).render(
        <CityGPT lang={lang} context={context} />,
      );
    }
  }

  customElements.define("city-gpt", CityGPTElement);

  // Check if there is already a <city-gpt> element on the page
  if (!document.querySelector("city-gpt")) {
    // If no <city-gpt> element exists, add one at the end of the document body
    const cityGptElement = document.createElement("city-gpt");
    document.body.appendChild(cityGptElement);
  }

  // Create a link element for the fonts CSS
  const fontLinkElem = document.createElement("link");
  fontLinkElem.setAttribute("rel", "stylesheet");
  fontLinkElem.setAttribute("href", `${PUBLISH_URL}/city-gpt-fonts.css`);
  fontLinkElem.setAttribute("id", "city-gpt-fonts");

  // Append it to the document head
  document.head.appendChild(fontLinkElem);

  console.log(STARTUP_MESSAGE);
} catch (error) {
  console.error("An error occurred during CityGPT startup:", error);
}
