import React, { useEffect, useState } from "react";
import { Root } from "~/src/components/_root/index";
import { ORG_NAME, BACKEND_URL } from "~src/config/env";
import { splitKeyValue } from "~/src/util/splitKeyValue";
import * as classes from "./style.module.scss";
import classNames from "classnames";
import { useFetch } from "~/src/hooks/useFetch"; // Import your useFetch hook
import i18n from "./i18n"; // Import i18n instance

import "./_root/style.scss";

interface CityGPTProps {
  lang: string | null;
  context: string | null;
}

import { BackendInfo, buildTree } from "../util/suggestions/suggestions";

export const StyleReset: React.FC<CityGPTProps> = ({ lang, context }) => {
  const [finalContext, setFinalContext] = useState<string | null>(context);
  const [userId, setUserId] = useState<string | null>(
    localStorage.getItem("uID") || sessionStorage.getItem("uID"),
  );
  const [cID, setCId] = useState<string | null>(null);

  useEffect(() => {
    fetch(`${BACKEND_URL}/log`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        referrer: document.referrer || null,
        initialQuery: null, // the initial query is not available in the CityGPT-Component
        uID: userId,
      }),
    })
      .then(async (response) => {
        const user = await response.json();
        if (user && user.id) {
          localStorage.setItem("uID", user.id);
          setUserId(user.id);
          setCId(user.cID);
        }
      })
      .catch((error) => {
        console.log("Error Log", error);
      });
  }, [userId]);

  // loads preferences and translations on startup
  const { data: preferences, error: preferencesError } = useFetch<BackendInfo>({
    url: `${BACKEND_URL}/info?source=${lang}:preferences`,
  });

  // this loads the suggested prompts on startup
  const { data: suggestions, error: suggestionsError } = useFetch<BackendInfo>({
    url: finalContext
      ? `${BACKEND_URL}/info?source=${lang}:suggestions:${finalContext}`
      : null,
  });

  const [startupFinished, setStartupFinished] = useState(false);
  const [fadeIn, setFadeIn] = useState(false); // New state to trigger the fade-in effect

  useEffect(() => {
    if (!finalContext && preferences) {
      const fullPath = window.location.pathname;

      // get all urls
      const urls = preferences?.autocomplete
        .filter((item) => item.value.startsWith("context"))
        .map((item) => item.value.replace("context.", ""))
        .map((item) => {
          const [key, value] = splitKeyValue(item);
          return { context: key, url: value };
        });

      // find the context by the url
      const found = urls.find((item) => fullPath.startsWith(item.url))?.context;

      if (found) {
        setFinalContext(found);
      } else {
        setFinalContext("default");
      }
    }

    if (preferences && suggestions && lang) {
      // build from the flat list a tree
      // Example of an item: { value: "translation.chat.question.buttonText:Frage stellen", source: "de:preferences" }
      const translations = preferences?.autocomplete
        .filter((item) => item.value.startsWith("translation"))
        .reduce((acc: Record<string, any>, item) => {
          const [key, value] = splitKeyValue(item.value);
          // the key is a dot separated string, so we need to split it to put it in the correct object
          const keys = key.split(".");
          keys.reduce((acc, key, index) => {
            if (index === keys.length - 1) {
              acc[key] = value;
            } else {
              acc[key] = acc[key] || {};
            }
            return acc[key];
          }, acc);
          return acc;
        }, {});
      i18n.addResourceBundle(lang, "translation", {
        ...translations.translation,
        // add the suggestions to the chat object
        chat: {
          ...translations.translation.chat,
          suggestions: buildTree(suggestions.autocomplete),
        },
      });
      setStartupFinished(true);
    }
  }, [preferences, suggestions, lang, finalContext]);

  useEffect(() => {
    if (startupFinished) {
      setFadeIn(true); // Trigger the fade-in effect after startup is finished
    }
  }, [startupFinished]);

  if (!startupFinished) {
    return null;
  }

  if (suggestionsError || preferencesError) {
    return null; // could handle error/loading states as needed but for now just return null
  }

  return (
    <div className={classNames([ORG_NAME, classes.root])}>
      <div
        className={classNames([
          classes.startup,
          { [classes.startupFinished]: fadeIn },
        ])}
      >
        <Root lang={lang} context={finalContext} userId={userId} cID={cID} />
      </div>
    </div>
  );
};

export default StyleReset;
