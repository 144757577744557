import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { MarkdownSimple } from "~/src/components/MarkdownSimple/index";

import IconCross from "jsx:~/src/components/_root/assets/cross.svg";

import * as classes from "./style.module.scss";
import * as classesExpandDimension from "~/src/components/ExpandDimension/style.module.scss";
import * as classesButton from "~/src/components/Button/style.module.scss";
import ExpandDimension from "~/src/components/ExpandDimension/index";

import classNames from "classnames";
import { useTranslation } from "react-i18next";

interface MainButtonProps {
  expanded: boolean;
  setExpanded: Dispatch<SetStateAction<boolean>>;
  inputRef: React.RefObject<HTMLInputElement>; // Add prop for inputRef
  closeModal: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const MainButton: React.FC<MainButtonProps> = ({
  expanded,
  setExpanded,
  closeModal,
  inputRef, // Access inputRef here
}) => {
  const [showIndication, setShowIndication] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.scrollY + window.innerHeight >=
        document.body.scrollHeight - 200
      ) {
        setShowIndication(true);
      } else {
        setShowIndication(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = () => {
    setExpanded(true);
    // Focus the input field when the button is clicked
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className={classes.wrapper}>
      <button
        className={classNames([
          classesExpandDimension.expandOnHover,
          classes.button,
        ])}
        onClick={handleClick}
      >
        <div
          className={classNames(["main-button__icon", classes.buttonIcon])}
        ></div>
        <div
          className={classNames(
            classes.buttonTextWrapper,
            showIndication && classes.buttonTextWrapperForceShow,
          )}
        >
          <ExpandDimension
            className={classNames([
              (expanded || showIndication) &&
                classesExpandDimension.forceExpand,

              // debug for hover
              // classesExpandDimension.isExpanded,
            ])}
          >
            <div
              className={classNames(classes.buttonText, {
                [classes.buttonTextHidden]: expanded,
              })}
            >
              <MarkdownSimple markdown={t("mainButton.hello")} />
              <div className={classNames(classes.buttonSpacing)}>
                <div className={classNames(classesButton.primaryButton)}>
                  <span>{t("mainButton.buttonText")}</span>
                </div>
              </div>
            </div>
          </ExpandDimension>
        </div>
      </button>
      <button
        className={classNames([
          classes.buttonClose,
          { [classes.buttonCloseExpanded]: expanded },
        ])}
        onClick={closeModal}
      >
        <div className={classNames(classes.closeIcon)}>
          <IconCross />
        </div>
      </button>
    </div>
  );
};
