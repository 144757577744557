import React, { useEffect, useState, useRef } from "react";
import { settings } from "~/src/config/settings";
import { BACKEND_URL } from "~src/config/env";

import classNames from "classnames";

// components
import { MainButton } from "~/src/components/MainButton/index";
import ExpandDimension from "~/src/components/ExpandDimension/index";
import { Chat } from "~/src/components/Chat/index";

// styles
import * as classesExpandDimension from "~/src/components/ExpandDimension/style.module.scss";
import * as classes from "./style.module.scss";

interface RootProps {
  lang: string | null;
  context: string | null;
  userId: string | null;
  cID: string | null;
}

export const Root: React.FC<RootProps> = ({ lang, context, userId, cID }) => {
  const [expanded, setExpanded] = useState(settings.expanded); // is the chat visible
  const inputRef = useRef<HTMLInputElement>(null); // Create the ref for the input

  useEffect(() => {
    // If the chat is expanded
    // do we need `position: fixed;`?
    // See: https://medium.com/@wangyazh0u/disable-body-scroll-on-mobile-safari-21270f61f9db

    if (expanded) {
      // Disable body scroll
      document.body.style.overflow = "hidden";
    } else {
      // Re-enable body scroll
      document.body.style.overflow = "";
    }

    // Cleanup on component unmount
    return () => {
      document.body.style.overflow = "";
    };
  }, [expanded]);

  if (!lang || !context) {
    return (
      <div className={classes.overlay}>
        <h1>Setup Error!</h1>
        <p>Please set lang and context in component</p>
      </div>
    );
  }

  const closeModal = (
    e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>,
  ) => {
    setExpanded(false);
  };

  return (
    <div className={classNames("root", expanded && "root--isExpanded")}>
      <div
        className={classNames(
          classes.background,
          expanded && classes.backgroundExpanded,
        )}
        onClick={closeModal}
      ></div>
      <div className={classNames("root__content", classes.content)}>
        <MainButton
          expanded={expanded}
          setExpanded={setExpanded}
          inputRef={inputRef}
          closeModal={closeModal}
        />

        <div
          className={classNames([
            classes.chat,
            expanded && classes.chatExpanded,
          ])}
        >
          {/* this expands the content on click  */}
          <ExpandDimension
            className={classNames([
              classes.expand,
              expanded && classesExpandDimension.forceExpand,
            ])}
          >
            <Chat
              expanded={expanded}
              baseURL={`${BACKEND_URL}`}
              lang={lang}
              inputRef={inputRef} // Pass inputRef to PromptForm via Chat
              userId={userId}
              cID={cID}
            />
          </ExpandDimension>
        </div>
      </div>
    </div>
  );
};
