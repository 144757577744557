import React, { FC, useState } from "react";
import { IconPlusMinus } from "~src/components/IconPlusMinus/index";
import IconArrowRight from "jsx:~/src/components/_root/assets/arrow-right.svg";
import ExpandDimension from "~/src/components/ExpandDimension/index";
import classNames from "classnames";
import * as classesLink from "~/src/components/UsedLinks/style.module.scss";
import { Suggestion } from "../../util/suggestions/suggestions";

import * as classes from "./style.module.scss";
import * as classesExpandDimension from "~/src/components/ExpandDimension/style.module.scss";

interface AccordionSectionProps {
  title: string;
  content?: Suggestion[];
  sendSubmit: (inputValue: string) => void;
}

export const AccordionSection: FC<AccordionSectionProps> = ({
  title,
  content,
  sendSubmit,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={classNames(classes.section)}>
      {content && content?.length > 0 ? (
        <>
          <button
            className={classNames(classes.header, [
              expanded && classes.isExpanded,
            ])}
            onClick={handleToggle}
            aria-expanded={expanded}
          >
            <h2>{title}</h2>
            <IconPlusMinus expanded={expanded} />
          </button>
          <ExpandDimension
            expandDirection="y"
            className={classNames([
              expanded && classesExpandDimension.forceExpand,
            ])}
          >
            <div className={classNames(classes.content)}>
              <ul>
                {content?.map((item: Suggestion) => (
                  <li key={item.id}>
                    <AccordionItem title={item.title} sendSubmit={sendSubmit} />
                  </li>
                ))}
              </ul>
            </div>
          </ExpandDimension>
        </>
      ) : (
        <button
          className={classNames(classes.header, classesLink.link)}
          onClick={() => sendSubmit(title)}
        >
          <h2 className={classesLink.linkTitle}>{title}</h2>
          <IconArrowRight />
        </button>
      )}
    </div>
  );
};

interface AccordionItemProps {
  title: string;
  sendSubmit: (inputValue: string) => void;
}

export const AccordionItem: React.FC<AccordionItemProps> = ({
  title,
  sendSubmit,
}) => {
  const handleClick = () => {
    sendSubmit(title);
  };

  return (
    <button className={classesLink.link} onClick={handleClick}>
      <IconArrowRight />
      <span className={classesLink.linkTitle}>{title}</span>
    </button>
  );
};
