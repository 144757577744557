.select {
  transition-property: background-color; // not the color to avoid flickering
  justify-content: space-between;
  padding: var(--size-2) var(--size-4);
  background-color: var(--colors--base--background);
  margin-bottom: var(--size-2);
  text-align: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.select:hover,
.selectActive {
  background-color: var(--colors--primary);
  color: var(--colors--background);
}

.title {
  margin-top: var(--size-6);
  margin-bottom: var(--size-2);
  display: block;
}

.input {
  display: block;
  width: 100%;

  padding: 0.5em;
  min-height: calc(var(--size-10));
}

.footer {
  margin-top: var(--size-4);
  margin-bottom: var(--size-10);
}
