import { FC } from "react";
import classNames from "classnames";
import * as classes from "./style.module.scss";

interface PlusMinusIconProps {
  expanded: boolean;
}

export const IconPlusMinus: FC<PlusMinusIconProps> = ({ expanded }) => {
  return (
    <div
      className={classNames(classes["icon"], {
        [classes.open]: expanded,
      })}
    >
      <div className={classNames(classes.line, classes.horizontal)} />
      <div className={classNames(classes.line, classes.vertical)} />
    </div>
  );
};
