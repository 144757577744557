import React, {
  Dispatch,
  SetStateAction,
  useState,
  ForwardedRef,
  forwardRef,
} from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import * as classes from "./style.module.scss";
import * as classesButton from "~/src/components/Button/style.module.scss";
import * as inputClasses from "~/src/components/Input/style.module.scss";

interface PromptFormProps {
  setInputValue: Dispatch<SetStateAction<string>>;
  inputValue: string;
  sendSubmit: (value: string) => void;
  submitDisabled: boolean;
  isLoading: boolean;
  ref: React.Ref<HTMLInputElement>;
}

export const PromptForm = forwardRef<HTMLInputElement, PromptFormProps>(
  (
    { setInputValue, inputValue, sendSubmit, submitDisabled },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const { t } = useTranslation();

    const handleSubmit = (event: React.FormEvent) => {
      event.preventDefault();
      if (submitDisabled) {
        return;
      }

      sendSubmit(inputValue);
    };

    const handleChange = (value: string) => {
      setInputValue(value);
    };

    return (
      <div className={classes.wrapper}>
        <label htmlFor="question" className={classes.srOnly}>
          {t("chat.question.label")}
        </label>
        <input
          ref={ref} // Attach the ref to the input
          className={classNames(
            "showWhenFocus",
            inputClasses.input,
            classes.input,
          )}
          placeholder={t("chat.question.placeholder", {
            defaultValue: "",
          })}
          value={inputValue}
          onChange={(e) => handleChange(e.target.value)}
          onKeyDown={(event) => {
            // this is needed for multiline support
            if (event.key === "Enter") {
              if (
                !document.activeElement?.getAttribute("aria-activedescendant")
              ) {
                handleSubmit(event);
              }
            }
          }}
        />

        <button
          type="submit"
          className={classNames(
            "button",
            "showWhenFocus",
            classesButton.primaryButton,
            classes.button,
          )}
          onClick={handleSubmit}
          disabled={submitDisabled}
        >
          <span className={classes.srOnly}>
            {t("chat.question.buttonText")}
          </span>
        </button>
      </div>
    );
  },
);
