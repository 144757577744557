import i18n from "i18next";
import LanguageDetector, {
  CustomDetector,
} from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

// if we want to always show one language as default, no matter what the browser says
// if you want to use the browser language, just remove the CustomDetector in the detection options
export const defaultLanguage = "de";

const myDetector: CustomDetector = {
  name: "myDetectorsName",

  lookup() {
    return defaultLanguage;
  },
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(myDetector);

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: { order: ["myDetectorsName"] },
    debug: false,
    fallbackLng: defaultLanguage,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      de: {
        translation: {
          // OLD
          question: {
            label: "Deine Frage",
            placeholder:
              "Frag mich etwas über die Verwaltung der Stadt Luzern.",
            buttonText: "Frage stellen",
          },
        },
      },
    },
  });

export default i18n;
