import React from "react";
import classNames from "classnames";
import * as classes from "./style.module.scss";

interface SpinnerProps {
  className?: string;
}

export const Spinner: React.FC<SpinnerProps> = ({ className }) => {
  return <div className={classNames(className, classes.spinner)}></div>;
};
