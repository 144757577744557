.primaryButton {
  transition-property: background-color; // not the color to avoid flickering
  color: var(--colors--background);
  display: inline-block;
  background-color: var(--colors--primary);
  border: 1px solid var(--colors--primary);

  padding: var(--size-2) var(--size-3);

  &:disabled {
    // background-color: var(--colors--primary);
    background-color: var(--colors--base--deactivated);
    border-color: var(--colors--base--deactivated);
  }

  &:hover:not(:disabled) {
    color: var(--colors--primary);
    background-color: var(--colors--background);
  }
}
