.wrapper {
  display: flex;
  // align-items: stretch;
  // align-content: stretch;
}

.srOnly {
  display: none;
}

.input {
  flex-grow: 1;
  padding-left: 0.5em;
  padding-right: 0.5em;
  height: var(--main-button--image--width);

  border-right-width: 0 !important;
}

.input:focus + .button {
  box-shadow: /* 
    bottom 
      */
    0.1rem 0.1rem 0 0.1rem
      color-mix(in srgb, white 75%, var(--colors--primary) 25%),
    /* 
    top 
      */ 0.1rem -0.1rem 0 0.1rem color-mix(in srgb, white 75%, var(
            --colors--primary
          )
          25%);
  &:disabled {
    border-top-color: var(--colors--primary);
    border-right-color: var(--colors--primary);
    border-bottom-color: var(--colors--primary);
  }
}
.button {
  box-shadow: none;
  padding: 0;
  transition-property: background-color, box-shadow, border-color;

  &:before {
    content: "";
    display: block;
    height: calc(var(--main-button--image--width) - 2px);
    width: calc(var(--main-button--image--width) - 2px);
    aspect-ratio: 1/1;

    // activate to color #0072bd
    // https://codepen.io/sosuke/pen/Pjoqqp

    filter: invert(100%) sepia(1%) saturate(98%) hue-rotate(170deg)
      brightness(115%) contrast(100%);

    background-image: url("/src/components/_root/assets/arrow-right.svg");
    // background-size: ;
    // background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  &:hover:not(:disabled)::before {
    // activate to color #ffffff
    // https://codepen.io/sosuke/pen/Pjoqqp

    filter: invert(29%) sepia(73%) saturate(1766%) hue-rotate(182deg)
      brightness(93%) contrast(102%);
  }
}
