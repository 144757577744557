:root,
:host {
  --chat--spacer--padding: var(--main-button--wrapper--padding);
}

// general styling for the chat answers
.markdown {
  em,
  strong {
    font-weight: 700;
    font-style: normal;
  }

  ol,
  ul {
    list-style-position: outside;
    margin: var(--size-3) 0 var(--size-6) var(--size-5);
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
    margin: var(--size-3) 0 var(--size-4) var(--size-4);
  }
  li {
    margin-top: var(--size-1);
  }
}

.wrapper {
  width: 100vw;
  max-width: calc(var(--rem) * 47);;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-top: 0;
  opacity: 0;
  transition-property: opacity;
  transition-delay: calc(var(--root--transition-duration));

  &.isExpanded {
    opacity: 1;
  }
}

.spacer {
  padding-left: var(--main-button--wrapper--padding);
  padding-right: var(--main-button--wrapper--padding);
}

.title {
  margin-bottom: var(--size-9);
}

.intro {
  padding-bottom: var(--main-button--wrapper--padding);

  p + p {
    margin-top: 0.7em;
  }
}

.legal {
  font-size: var(--size-3);
  margin-top: var(--size-3);
}

.content {
  flex-grow: 1;
  overflow-y: auto;
}

.footer {
  flex-shrink: 0; /* Prevent footer from shrinking */
  /* Additional styles to ensure footer sticks to the bottom */
  margin-top: auto; /* Push footer to the bottom */

  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(2px);

  position: sticky;
  bottom: 0;

  padding-top: var(--size-3);
  padding-bottom: 16px;
}

.spinner {
  margin-top: var(--size-3);
}
