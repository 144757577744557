.spinner {
  border: 2px solid var(--colors--base--deactivated);
  border-top: 2px solid var(--colors--primary);
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation: spin 2s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
