import { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { MarkdownLine } from "~/src/components/MarkdownSimple/index";

import * as classes from "./style.module.scss";
import * as classesButton from "~/src/components/Button/style.module.scss";
import * as inputClasses from "~/src/components/Input/style.module.scss";

type Category = "quality" | "impact";

interface Classification {
  impact?: number;
  quality?: number;
}

interface ClassificationButtonProps {
  cat: Category;
  rating: number;
  text: string;
  classification: Classification;
  handleClassification: (cat: Category, rating: number) => void;
}

const ClassificationButton: React.FC<ClassificationButtonProps> = ({
  cat,
  rating,
  text,
  classification,
  handleClassification,
}) => (
  <button
    className={classNames(classes.select, {
      [classes.selectActive]: classification[cat] === rating,
    })}
    onClick={() => {
      handleClassification(cat, rating);
    }}
  >
    <MarkdownLine markdown={text} />
  </button>
);

interface FeedbackProps {
  requestId: string | null;
  inputValue: string;
  baseURL: string;
  userId: string | null;
}

export const Feedback: React.FC<FeedbackProps> = ({
  requestId,
  baseURL,
  userId,
  inputValue,
}) => {
  const { t } = useTranslation();

  const [classification, setClassification] = useState<Classification>({
    impact: undefined,
    quality: undefined,
  });
  const [feedbackValue, setFeedbackValue] = useState<string>("");
  const [statusMessage, setStatusMessage] = useState<string>("INIT");
  const [emailValue, setEmailValue] = useState<string>(
    sessionStorage.getItem("email") || "",
  );

  const handleClassification = (cat: Category, rating: number) => {
    setClassification((prevClassification) => ({
      ...prevClassification,
      [cat]: rating,
    }));
  };

  const sendFeedback = async () => {
    if (!feedbackValue) {
      return;
    }
    const body: Record<string, string | undefined> = {
      query: inputValue || "",
      email: emailValue,
      body: feedbackValue,
      rID: requestId || undefined,
      uID: userId || undefined,
    };
    sessionStorage.setItem("email", emailValue);

    try {
      const response = await fetch(`${baseURL}/feedback`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const data = await response.text();
      if (data !== "OK") {
        throw new Error("Feedback submission failed");
      }
    } catch (error) {
      console.error("Error sending feedback data:", error);
      throw error; // Re-throw to handle in handleSend
    }
  };

  const sendClassification = async () => {
    const body: Record<string, string | number | undefined> = {
      ...classification,
      rID: requestId || "",
    };

    try {
      const response = await fetch(`${baseURL}/classification`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const data = await response.text();
      if (data !== "OK") {
        throw new Error("Classification submission failed");
      }
    } catch (error) {
      console.error("Error sending classification data:", error);
      throw error; // Re-throw to handle in handleSend
    }
  };

  const handleSend = async () => {
    setStatusMessage(""); // Clear previous status message
    try {
      await Promise.all([sendClassification(), sendFeedback()]);
      setStatusMessage("OK");
    } catch (error) {
      setStatusMessage("ERROR");
    }
  };

  return (
    <>
      {statusMessage === "INIT" && (
        <>
          <div>
            <h2 className={classes.title}>{t("feedback.quality.title")}</h2>
            {["3", "2", "1", "4", "0"].map((rating) => (
              <ClassificationButton
                key={rating}
                handleClassification={handleClassification}
                text={t(`feedback.quality.${rating}`)}
                cat={"quality"}
                classification={classification}
                rating={parseInt(rating)}
              />
            ))}
          </div>
          <div>
            <h2 className={classes.title}>{t("feedback.impact.title")}</h2>
            {["1", "2", "3", "4", "5", "0"].map((rating) => (
              <ClassificationButton
                key={rating}
                handleClassification={handleClassification}
                text={t(`feedback.impact.${rating}`)}
                cat={"impact"}
                classification={classification}
                rating={parseInt(rating)}
              />
            ))}
          </div>
          <div>
            <label
              className={classNames("h2", classes.title)}
              htmlFor="feedback"
            >
              {t("feedback.messageLabel")}
            </label>
            <textarea
              className={classNames(
                "showWhenFocus",
                inputClasses.input,
                classes.input,
              )}
              id="feedback"
              value={feedbackValue}
              onChange={(event) => setFeedbackValue(event.target.value)}
            ></textarea>
          </div>
          <div>
            <label className={classNames("h2", classes.title)} htmlFor="email">
              {t("feedback.emailLabel")}
            </label>
            <input
              className={classNames(
                "showWhenFocus",
                inputClasses.input,
                classes.input,
              )}
              id="email"
              value={emailValue}
              onChange={(event) => setEmailValue(event.target.value)}
            />
          </div>
        </>
      )}

      <div className={classNames([classes.footer])}>
        {statusMessage === "INIT" && (
          <button
            disabled={
              classification.quality === undefined ||
              classification.impact === undefined
            }
            className={classNames([
              "showWhenFocus",
              classesButton.primaryButton,
            ])}
            onClick={() => handleSend()}
          >
            <span>{t("feedback.send")}</span>
          </button>
        )}
        {statusMessage === "OK" && <h2>{t("feedback.feedbackReceived")}</h2>}
        {statusMessage === "ERROR" && (
          <h2>{t("feedback.feedbackReceivedError")}</h2>
        )}
      </div>
    </>
  );
};
