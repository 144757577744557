// @import url("//fonts.signalwerk.ch/css/latest/family=Work+Sans:ital,wght@0,100..900;1,100..900.css");

:root,
:host {
  --root--h1--font-size: 26px;
  --root--h2--font-size: 20px;
  --rem: 18px;
  --root--base--font-size: var(--rem);
  --root--transition-duration: 350ms;
  --root--transition-timing-function: ease-in;
}

.root {
  // reset alls styles
  all: unset;
  font-family: "CityGPT", "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: var(--root--base--font-size);
  line-height: 1.35;

  * {
    transition-duration: var(--root--transition-duration);
    transition-timing-function: var(--root--transition-timing-function);
  }
}
.startup {
  // not showing before startup
  transition-property: opacity;
  opacity: 0;

  // Final state after component mounts
  &Finished {
    opacity: 1;
  }
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font: inherit; /* Get rid of all font sizes and heights */
}

h1,
h2,
:global(.h2) {
  line-height: 1.3;
}

h1 {
  font-size: var(--root--h1--font-size);
}

:global(.h2),
h2 {
  font-size: var(--root--h2--font-size);
  font-weight: 700;
}

p {
  margin: 0;
}

hr {
  border: 0;
  border-top: 1px solid var(--colors--primary);
  margin: var(--size-4) 0;
}

input,
textarea,
button {
  color: inherit;
  border: 0;
  box-shadow: none;
  background: transparent;
  text-align: inherit;
  text-shadow: none;
  margin: 0;
  padding: 0;

  &:focus {
    outline: 0 !important;
  }
}

:global(.showWhenFocus) {
  &:focus {
    box-shadow: 0 0 0 0.2rem
      color-mix(in srgb, transparent 75%, var(--colors--primary) 25%);
  }
}

button {
  cursor: pointer;

  &:disabled {
    cursor: auto;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

:global(.a--cta) {
  color: var(--colors--primary);

  &:hover {
    color: inherit;
  }
}

:global(.a--underline) {
  text-underline-offset: 0.3em;
  text-decoration: underline currentColor;
  text-decoration-thickness: 1.5px;
}
:global(.a--nowrap) {
  white-space: nowrap;
}
