import { useMemo } from "react";
import IconArrowRight from "jsx:~/src/components/_root/assets/arrow-right.svg";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { UsedLinksDto } from "~/src/components/Chat/helpers";
import * as classes from "./style.module.scss";

type UsedLinksProps = {
  className?: string;
  links: UsedLinksDto[];
};

enum SearchResultType {
  PDF = "pdf",
}

const renderLink = (result: UsedLinksDto) => {
  switch (result.type) {
    case SearchResultType.PDF:
      return (
        <li>
          <a
            className={classes.link}
            href={result.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className={classes.prefix} />
            <IconArrowRight />
            <span className={classes.linkTitle}>{result.title}</span>
          </a>
        </li>
      );
    default:
      const breadcrumbsText = result.title?.split(">") || [];
      const title = breadcrumbsText.pop()?.trim() || "";

      return (
        <li>
          <a className={classes.link} href={result.url} target="_blank">
            <span className={classes.prefix} />
            <IconArrowRight />
            <span className={classes.linkTitle}>{title}</span>
          </a>
          {/* <p>{result.description}</p> */}
        </li>
      );
  }
};

const UsedLinks: React.FC<UsedLinksProps> = ({ links }) => {
  const { t } = useTranslation();

  // const searchResults: SearchResult[] = useMemo(() => {
  //   return links.map((link) => {
  //     const breadcrumbsText = link.title?.split(">") || [];
  //     const title = breadcrumbsText.pop() || "";
  //     const breadcrumbs = breadcrumbsText
  //       .map((breadcrumb) => breadcrumb.trim())
  //       .map((breadcrumb) => ({ text: breadcrumb, link: "" }));

  //     return {
  //       title,
  //       image: link.smallImage,
  //       description: link.description || "",
  //       date: link.date ? dayjs(link.date).format("dddd, D. MMMM YYYY") : "",
  //       breadcrumbs: breadcrumbs,
  //       currentSection: link.considered
  //         ? t("answers.considered_articles")
  //         : t("answers.not_considered_articles"),
  //       link: link.url,
  //       summary: link.summary || "",
  //       type: link.type || null,
  //     };
  //   });
  // }, [links, t]);

  const consideredSearchResults: UsedLinksDto[] = useMemo(() => {
    return links.filter((result) => result.considered);
  }, [links]);

  if (!consideredSearchResults.length) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      {consideredSearchResults.length > 0 && (
        <div className={classNames(classes.box)}>
          {/* <pre>{JSON.stringify(links, null, 2)}</pre> */}
          <>
            <h2>{t("chat.answer.consideredArticles")}</h2>
            <ol>
              {consideredSearchResults.map((result) => renderLink(result))}
            </ol>
          </>
        </div>
      )}
    </div>
  );
};

export default UsedLinks;
