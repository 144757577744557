:root,
:host {
  --main-button--wrapper--padding: var(--size-4);
  --main-button--wrapper--box-shadow: none;

  // size of the button
  --main-button--image--width: var(--size-10);
  --main-button--image--aspect-ratio: 1/1;

  --main-button--text--max-width: calc(var(--rem) * 12);
  --main-button--text--padding-left: var(--size-4);

  :global(.root--isExpanded) {
    --main-button--wrapper--padding: var(--size-3);
  }
}

@media only screen and (min-width: 800px) {
  :root,
  :host {
    :global(.root--isExpanded) {
      --main-button--wrapper--padding: var(--size-9);
    }
  }
}

.button {
  display: flex;
  flex-wrap: nowrap;
  padding: var(--main-button--wrapper--padding);
}

.buttonSpacing {
  padding: 0;
  padding-top: var(--size-4);
}

.buttonIcon {
  width: var(--main-button--image--width);
  aspect-ratio: 1/1;
}

.buttonClose {
  position: absolute;
  right: var(--main-button--wrapper--padding);
  top: var(--main-button--wrapper--padding);
  background-color: var(--colors--base--background);
  color: var(--colors--primary);

  height: calc(var(--main-button--image--width) - 2px);
  width: calc(var(--main-button--image--width) - 2px);
  aspect-ratio: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  transform: translateX(200%);
  opacity: 0;
  transition-property: transform, opacity;

  &Expanded {
    transform: translateX(0);
    opacity: 1;
  }

  &:hover {
    color: var(--colors--primary--hover);
  }
}

.closeIcon {
  width: 20px;
  height: 20px;
}

.buttonTextWrapper {
  transition-property: opacity;
  transition-delay: calc(var(--root--transition-duration));

  opacity: 0;
  overflow: hidden;

  :global(.root):hover &,
  &ForceShow {
    opacity: 1;
  }
}

.buttonText {
  line-height: 1.25;
  width: calc(
    var(--main-button--text--max-width) + var(--main-button--text--padding-left)
  );
  padding-left: var(--main-button--text--padding-left);
  max-height: 20em;

  &Hidden {
    opacity: 0;
    max-height: 0;
  }
}

.wrapper {
  flex: 0;
}
