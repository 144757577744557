.wrapper {
  padding-top: var(--size-9);

  a {
    font-weight: 700;
    &:hover {
      color: var(--colors--base);
    }
  }

  ol {
    width: 100%;
    color: var(--colors--primary);
    margin-top: var(--size-4);

    list-style: none;
    counter-reset: item;
  }
  ol li {
    counter-increment: item;
  }
  li + li {
    margin-top: var(--size-2);
  }
  ol li .prefix::before {
    content: counter(item) " ";
    display: inline-block;
    width: var(--size-4);
    color: inherit;
    font-weight: bold;
  }
}

.link {
  width: 100%;
  display: flex;
  gap: var(--size-2);

  > * {
    flex-shrink: 0;
  }
}

.linkTitle {
  flex-shrink: 1;
  overflow: hidden;
  flex-shrink: 1;
  overflow-wrap: break-word;
}

.box {
  background-color: var(--colors--primary--background);

  display: flex;
  padding: var(--size-3) var(--size-3) var(--size-5) var(--size-3);
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
