.input {
  background-color: white;
  display: block;
  border: solid 1px var(--colors--base--deactivated);
  color: var(--colors--primary);

  &:focus {
    border-color: var(--colors--primary);
  }
}
