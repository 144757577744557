.section {
  margin-bottom: var(--size-2);
}

.header {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: var(--size-2) var(--size-4);
  background-color: var(--colors--base--background);
  color: var(--colors--primary);
  &:hover,
  &.isExpanded {
    color: inherit;
  }
}

.content {
  background-color: var(--colors--base--background);

  li {
    padding: var(--size-2) var(--size-4);
    color: var(--colors--primary);
    transition-property: color;
    &:hover {
      color: inherit;
    }
  }
}
