:root,
:host {
  --root--wrapper--margin: var(--size-2);

  :global(.root--isExpanded) {
    --root--wrapper--margin: 0px;
  }
}

@media only screen and (min-width: 800px) {
  :root,
  :host {
    :global(.root--isExpanded) {
      --root--wrapper--margin: var(--size-2);
    }
  }
}

.content {
  color: var(--colors--base);
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  transition-property: height;

  max-height: calc(100vh - var(--root--wrapper--margin) * 2);

  margin: var(--root--wrapper--margin);
  background-color: var(--colors--background);
}

.chat {
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;

  transition-property: height;
  height: 0;
  &Expanded {
    height: calc(
      100vh - var(--root--wrapper--margin) * 2 - var(
          --main-button--wrapper--padding
        ) * 2 - var(--main-button--image--width)
    );
  }
}

.expand {
  height: 100%;
}

.background {
  // opacity: 0;
  position: fixed;
  border-radius: 50%;

  --main-button--background--size: var(--main-button--image--width);

  bottom: calc(
    var(--root--wrapper--margin) + var(--main-button--wrapper--padding) +
      var(--main-button--image--width) / 2
  );
  right: calc(
    var(--root--wrapper--margin) + var(--main-button--wrapper--padding) +
      var(--main-button--image--width) / 2
  );
  width: var(--main-button--background--size);
  aspect-ratio: 1/1;

  transform: translate(50%, 50%);

  background-color: color-mix(
    in srgb,
    transparent 20%,
    var(--colors--primary) 80%
  );

  pointer-events: none;

  transition-property: opacity, bottom, right, width;

  &Expanded {
    opacity: 1;
    pointer-events: all;
    --main-button--background--size: 300vmax;
  }
}

.overlay {
  // centered overlay for warning
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  padding: var(--size-3);
  z-index: 1000;
  color: white;
  text-align: center;
  width: 50%;
  max-width: 500px;
  font-size: 1.5rem;
  font-weight: 700;
}
