import dayjs from "dayjs";
import { TFunction } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import isMobileDevice from "ismobilejs";
import { DEFAULT_MARKDOWN_RENDERERS, Markdown } from "react-marked-renderer"; // Get reference to the original link renderer
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Renderers } from "react-marked-renderer";
import { UsedLinksDto } from "~/src/components/Chat/helpers";

export const isTouchDevice = window
  ? isMobileDevice(window?.navigator).any
  : false;
export const isMobilePhone = window
  ? isMobileDevice(window?.navigator).phone
  : false;

export const showGeneratedSummary = (result: {
  description?: string;
  summary?: string;
  type?: string | null | undefined;
}) => {
  if (!!result.summary && result.type === "pdf") {
    return true;
  }
  return (
    (!result.description || result.description.length < 140) && !!result.summary
  );
};

const MyLinkComponent = ({
  href,
  children,
  tooltipContent,
}: {
  href: string | undefined;
  tooltipContent: string | undefined;
  children: JSX.Element[];
}) => {
  const [tooltipShown, setTooltipShown] = useState(false);
  const linkRef = useRef<HTMLAnchorElement | null>(null); // Using a ref to reference the link DOM element

  useEffect(() => {
    // Function to handle document click
    const handleDocumentClick = (e: any) => {
      // just set it to not shown if the click was not on the link
      // this is a bit hacky, but it works to make it possible to click a second time on the link to show the tooltip
      // otherwise tooltipShown will always be true, since we don't really now, when it's hidden again
      if (tooltipShown && e.target !== linkRef.current) {
        setTooltipShown(false);
      }
    };

    // Add the listener
    if (isTouchDevice) {
      document.addEventListener("click", handleDocumentClick);
    }
    // Cleanup the listener on unmount
    return () => {
      if (isTouchDevice) {
        document.removeEventListener("click", handleDocumentClick);
      }
    };
  }, [tooltipShown]);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (isTouchDevice) {
      if (!tooltipShown) {
        e.preventDefault(); // Prevent the link from being followed
        setTooltipShown(true);
      }
    }
    // No special handling for desktop; let the link work normally
  };

  return (
    <a
      ref={linkRef}
      onClick={handleClick}
      href={href}
      className={"a--cta a--nowrap"}
      data-tooltip-html={tooltipContent}
      target={href?.startsWith("http") ? "_blank" : undefined}
    >
      {children}
    </a>
  );
};

const rend = (usedLinks: UsedLinksDto[], t: TFunction) => {
  const renderers: Renderers = {
    ...DEFAULT_MARKDOWN_RENDERERS,
    link: ({
      href,
      title,
      children,
    }: {
      href: string | undefined;
      title: string | undefined;
      children: JSX.Element[];
    }) => {
      const target = href?.startsWith("http") ? "_blank" : undefined;
      if (!title) {
        return (
          <a href={href} target={target}>
            {children}
          </a>
        );
      }

      let tooltipContent = href;

      if (title?.startsWith("__link:")) {
        const index = parseInt(title?.split(":")[1]);
        if (usedLinks[index - 1]) {
          const link = usedLinks[index - 1];
          tooltipContent = `<div class="tooltip-content"><a href="${
            link.url
          }" target="${target}" class="tooltip-title">${link.title || link.url}</a>
<span class="tooltip-date">${link.date ? ` ${t("from")} ${dayjs(link.date).format("D. M. YYYY")}` : ""}</span>
${
  link.description
    ? `<br/><br/><span class="description">
${link.description}</span>`
    : ""
}
${showGeneratedSummary(link) ? `<br/><br/><span class="summary">${link.summary}</span>` : ""}
</div>`;
        }
      }
      return (
        <MyLinkComponent href={href} tooltipContent={tooltipContent}>
          {children}
        </MyLinkComponent>
      );
    },
    image: ({
      src,
      alt,
      title,
    }: {
      src: string | undefined;
      alt: string | undefined;
      title: string | undefined;
    }) => <img src={src} alt={alt} title={title} loading="lazy" />,
  };
  return renderers;
};
const MemoMarkdown = React.memo(
  (props: { keyString: string; markdown: string; usedLinks?: UsedLinksDto[] }) => {
    const { t } = useTranslation();

    return (
      <Markdown
        key={props.keyString}
        options={{ breaks: true, gfm: true }}
        markdown={props.markdown}
        renderers={rend(props.usedLinks || [], t)}
      />
    );
  },
  (prev, next) => prev.keyString === next.keyString,
);

const MyMarkdown = React.memo(
  (props: { markdown: string; usedLinks?: UsedLinksDto[] }) => {
    // fixes lists with numbers

    const markdown = props.markdown
      .replace(/\n\n([0-9\-*])/g, "\n$1")
      .split("\n\n");

    return (
      <ErrorBoundary
        fallback={
          <div>
            Something went wrong. Please try turning off automatic translation
            via Google Chrome, if you have it enabled!
          </div>
        }
      >
        {markdown.map((m, index) => {
          const key = `${index}_${m.length}_${m.substring(m.length - 20, m.length)}`;
          return (
            <MemoMarkdown
              keyString={`s_${key}`}
              key={key}
              markdown={m.trim().replace(/([-0-9.] [0-9]+)\./g, "$1\\.")}
              usedLinks={props.usedLinks}
            />
          );
        })}
      </ErrorBoundary>
    );
  },
);

export default MyMarkdown;
