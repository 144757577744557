export const isDev = process.env.NODE_ENV === "development";

export const HOST_POSTFIX = ".api.dev.genai.liip.ch";
export const ORG_NAME = process.env.ORG_NAME;
export const BACKEND_URL = isDev
  ? `/api/${ORG_NAME}`
  : `https://${ORG_NAME}${HOST_POSTFIX}/${ORG_NAME}`;
export const PUBLISH_URL = isDev
  ? ""
  : `https://${ORG_NAME}.chatbot-component.dev.genai.liip.ch`;

// see
// deployment/ci/env.sh
// for the environment variables

export const STARTUP_MESSAGE = `
CityGPT-Component
  ORG_NAME: ${process.env.ORG_NAME}
  TIMESTAMP: ${process.env.CI_COMMIT_TIMESTAMP}
  GIT: ${process.env.CI_COMMIT_SHORT_SHA}
`;
